import * as React from 'react';
import * as UI from '../Svg';

export interface SemanticProps {
  width?:  number;
  height?: number;
  color:   string;
}

export const Semantic: React.FC<SemanticProps> = React.forwardRef((
  props,
  ref: React.Ref<any>,
) => {
  return (
    <UI.Svg
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
    {({ color }) => (
      <>
        <circle cx="4" cy="18" r="3" fill={color} />
        <circle cx="9" cy="10" r="5" fill={color} />
        <circle cx="3" cy="3" r="3" fill={color} />
        <circle cx="18" cy="7" r="3" fill={color} />
      </>
    )}
    </UI.Svg>
  );
});
