// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as Svg                                  from 'components/@UI/@Svg';
import { TelegramIcon } from "components/@Elements/@Footer/TelegramIcon"


const colors = helpers.theme.colors;

const Container = styled('footer')`
  width: 100%;
  display: flex;
  height: 48px;
  background: #E3E6ED;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled(L.Flex)`
  max-width: 1144px;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
`;

const Content1 = styled(L.Flex)`
  align-items: center;
`;

const Content2 = styled(L.Flex)`
  align-items: center;
`;

const LabelLink = styled(UI.Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #888B9D;
  margin-right: 10px;
  text-decoration: none;
`;

const LabelLinkBold = styled(LabelLink)`
  font-weight: bold;
`;

const MailLink = styled(UI.Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
`;

export type BottomFooterProps = {};

export const BottomFooter: React.FC<BottomFooterProps> = ({}) => (
  <Container>
    <Wrapper>
      <Content1>
        {/* <LabelLink href="/public-offer">
          <intl.FormattedMessage id="bottomFooter.publicOffer" />
        </LabelLink>
        <LabelLink href="/privacy">
          <intl.FormattedMessage id="bottomFooter.privacy" />
        </LabelLink> */}
        <LabelLinkBold>ASOONE</LabelLinkBold>
        <LabelLink>{`© ${new Date().getFullYear()}`}</LabelLink>
      </Content1>
      <Content2>
        <LabelLink>
          <intl.FormattedMessage id="bottomFooter.company" />
        </LabelLink>
        <MailLink href={'mailto:inbox@asoone.io'}>
          <intl.FormattedMessage id="bottomFooter.mail" />
        </MailLink>
      </Content2>
    </Wrapper>
  </Container>
);
