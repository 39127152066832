import * as React                                from 'react';
import * as $Header                              from 'components/@Elements/@Header';
import * as $Footer                              from 'components/@Elements/@Footer';
import                                                './layout.css';
import styled from 'styled-components';
import { Country } from "components/@Elements/@Header/LanguageSelector/types"

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  min-width: 320px;
`;

const Main = styled('main')`
  flex: 1 0 0;
  padding-top: 46.4px;
`;

const HeaderWrapper = styled('div')`
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  min-width: 320px;
`;

export type LayoutProps = {
  language: Country;
  loginUrl: string;
  registerUrl: string;
  onLoginClick(): void;
  onRegisterClick(): void;
  onLangSelect(lang: Country): void;
};

const Layout: React.FC<LayoutProps> = ({
  children,
  loginUrl,
  registerUrl,
  onLoginClick,
  onRegisterClick,
  language,
  onLangSelect,
}) => {
  return (
    <Container>
      <HeaderWrapper>
        <$Header.Header
          onLoginClick={onLoginClick}
          onRegisterClick={onRegisterClick}
          registerUrl={registerUrl}
          loginUrl={loginUrl}
          language={language}
          onLangSelect={onLangSelect}
        />
      </HeaderWrapper>
      <Main>{children}</Main>
      {/* <$Footer.TopFooter /> */}
      <$Footer.BottomFooter />
    </Container>
  );
}


export default Layout;

// siteTitle={data.site.siteMetadata.title}
// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `)
