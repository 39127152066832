// Dependencies
import * as React        from 'react';

// Components
import { Flex, FlexProps } from './Flex';


// const containerWidth = {
//   xs: '100%',
//   sm: '100%',
//   md: '100%',
//   lg: '960px',
//   xl: '1200px',
// };

export interface ContainerProps extends FlexProps {}

export const Container: React.FC<ContainerProps> = ({
  children, ...props
}) => {
  return (
    <Flex flex={'0 0 1144px'} px={'32px'} mx={'auto'} {...props}>
      {children}
    </Flex>
  );
};
