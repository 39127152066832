import * as React from 'react';
import styled from 'styled-components';
import * as intl from 'gatsby-plugin-intl';
import * as helpers from 'helpers/index';
import * as UI from 'components/@UI';
import * as L from 'components/@UI/@Layout';
import * as Svg from 'components/@UI/@Svg';
import { LanguageSelector } from "components/@Elements/@Header/LanguageSelector/LanguageSelector"
import { Country } from "components/@Elements/@Header/LanguageSelector/types"
import { TelegramIcon } from "components/@Elements/@Footer/TelegramIcon"


const colors = helpers.theme.colors;

const Container = styled('header')`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled(L.Flex)`
  padding: 0 24px;
  max-width: 1112px;
  margin: 0 auto;
  flex: 1 0 0;
  flex-wrap: wrap;
  @media screen and (min-width: 1024px) {
    padding: 0 48px;
  }
  @media screen and (min-width: 1440px) {
    padding: 0;
  }
`;

const LanguageSelectorWrapper = styled(L.Flex)`
  margin-left: auto;
  width: 100px;
`;

const LinkWrapper = styled(L.Flex)`
  align-items: center;
  margin-right: 18px;
  //margin-left: 18px;
  margin-left: auto;
`;

const RegistrationButton = styled(UI.Button)`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;

  background: #E36A9D;
  border-radius: 6px;
  padding: 0 16px;
  display: flex;
  align-items: center;
`;

const RegisterLink = styled(UI.Link)`
  text-decoration: none;
  display: inline-block;
`;

const LogoWrapper = styled(L.Box)`
  display: none;
  @media screen and (min-width: 475px) {
    display: inline-block;
  }
`;

const LogoWrapper2 = styled(L.Box)`
  display: inline-block;
  @media screen and (min-width: 475px) {
    display: none;
  }
`;

export type HeaderProps = {
  language: Country;
  loginUrl: string;
  registerUrl: string;
  onLoginClick(): void;
  onRegisterClick(): void;
  onLangSelect(lang: Country): void;
};

export const Header: React.FC<HeaderProps> = ({
  loginUrl,
  registerUrl,
  onLoginClick,
  onRegisterClick,
  language,
  onLangSelect,
}) => {
  const handleRegisterClick = (e): void => {
    // e.preventDefault();
    onRegisterClick();
    // // @ts-ignore
    // if (typeof window.Calendly != "undefined") {
    //   // @ts-ignore
    //   window.Calendly.initPopupWidget({url: 'https://calendly.com/asoone'});
    // }
  };
  return (
    <Container>
      <Wrapper alignItems="center" justifyContent={'space-between'}>
        <LogoWrapper>
          <Svg.AsooneLogo
            width={'180px'}
            height={'40px'}
            color={colors.palette.bluepanLight}
          />
        </LogoWrapper>
        <LogoWrapper2>
          <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="32.5625" cy="28.8944" r="8.98277" fill="#4442A8"/>
            <path d="M20.7725 14.6681C17.0131 12.4977 15.7251 7.69064 17.8955 3.9313C20.066 0.171956 24.8731 -1.11612 28.6324 1.05433L26.3867 4.94392C30.1461 7.11438 31.4341 11.9215 29.2637 15.6808C27.0932 19.4402 22.2862 20.7282 18.5268 18.5577L20.7725 14.6681Z" fill="#4442A8"/>
            <path d="M9.5442 10.9287L19.0884 30.0171H0L9.5442 10.9287Z" fill="#4442A8"/>
          </svg>
        </LogoWrapper2>

        <UI.Link href='#price' style={{ marginLeft: '55px', textDecoration: 'none', background: '#fff' }}>
          <UI.Button
            size={'lg'}
            style={{
              color: '#5552ED',
              background: '#fff',
              border: '2px solid #E3E6ED',
              fontSize: '14px'
            }}
          >
          <UI.Regular15>
            <intl.FormattedMessage id="header.priceLink" />
          </UI.Regular15>
          </UI.Button>
        </UI.Link>

        {/* <$Header.Navigation /> */}
        {/*<LanguageSelectorWrapper>*/}
        {/*  <LanguageSelector selectedLang={language} onLangSelect={onLangSelect} />*/}
        {/*</LanguageSelectorWrapper>*/}
        <LinkWrapper>
          <UI.Link href={loginUrl} onClick={onLoginClick}>
            <UI.Regular15>
              <intl.FormattedMessage id="header.loginLink" />
            </UI.Regular15>
          </UI.Link>
        </LinkWrapper>
        <RegisterLink href={registerUrl} target="_blank" onClick={handleRegisterClick}>
          <RegistrationButton color={'pink'}>
            <intl.FormattedMessage id="header.registrationBtn3" />
          </RegistrationButton>
        </RegisterLink>
      </Wrapper>
    </Container>
  );
};


{/* <header>version,
<div
  style={{
    margin: `0 auto`,
    maxWidth: 960,
    padding: `1.45rem 1.0875rem`,
  }}
>
  <h1 style={{ margin: 0 }}>
    <gatsby.Link
      to="/"
      style={{
        color: `white`,
        textDecoration: `none`,
      }}
    >
      {siteTitle}
    </gatsby.Link>
  </h1>
</div>
</header> */}
