import * as React from 'react';
import * as UI from '../Svg';

export interface BubbleProps {
  width?:  number;
  height?: number;
  color:   string;
}

export const Bubble: React.FC<BubbleProps> = React.forwardRef((
  props,
  ref: React.Ref<any>,
) => {
  return (
    <UI.Svg
      viewBox={'0 0 13 13'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      {({ color }) => (
        <>
          <mask id="path-1-inside-1" fill="white">
            <path d="M7.5 11.3H5.5V11.8C5.5 12.0761 5.72386 12.3 6 12.3H7C7.27614 12.3 7.5 12.0761 7.5 11.8V11.3Z"/>
          </mask>
          <path d="M7.5 11.3H5.5V11.8C5.5 12.0761 5.72386 12.3 6 12.3H7C7.27614 12.3 7.5 12.0761 7.5 11.8V11.3Z" fill={`${color || '#828BB8'}`}/>
          <path d="M5.5 11.3V9.8H4V11.3H5.5ZM7.5 11.3H9V9.8H7.5V11.3ZM5.5 12.8H7.5V9.8H5.5V12.8ZM7 10.8H6V13.8H7V10.8ZM6 11.3V11.8H9V11.3H6ZM7 11.8V11.3H4V11.8H7ZM6 10.8C6.55228 10.8 7 11.2477 7 11.8H4C4 12.9046 4.89543 13.8 6 13.8V10.8ZM7 13.8C8.10457 13.8 9 12.9046 9 11.8H6C6 11.2477 6.44772 10.8 7 10.8V13.8Z" fill={`${color || '#828BB8'}`} mask="url(#path-1-inside-1)"/>
          <path d="M5.5 8.55H7.5C7.63807 8.55 7.75 8.66193 7.75 8.8V10.5194C7.75 10.5953 7.71548 10.6671 7.65617 10.7146L7.30539 10.9952C7.26106 11.0307 7.20599 11.05 7.14922 11.05H5.85078C5.79401 11.05 5.73894 11.0307 5.69461 10.9952L5.34383 10.7146C5.28452 10.6671 5.25 10.5953 5.25 10.5194V8.8C5.25 8.66193 5.36193 8.55 5.5 8.55Z" fill={`${color || '#828BB8'}`} stroke={`${color || '#828BB8'}`} strokeWidth="1.5"/>
          <path d="M6.5 0.799999C8.5 0.799999 10 2.35537 10 4.3C10 5.68702 9.14951 5.8357 8.72935 7.31882C8.42828 8.38156 7.60457 9.3 6.5 9.3C5.39543 9.3 4.57162 8.38159 4.27074 7.31879C3.85061 5.83479 3 5.68521 3 4.3C3 2.35537 4.5 0.799999 6.5 0.799999Z" stroke={`${color || '#828BB8'}`} strokeWidth="1.5"/>
        </>
      )}
    </UI.Svg>
  );
});
