// Local
import _palette     from './palette';


interface Color {
  hex:   string;
  light: boolean;
}

export type Palette = {
  [k in keyof typeof _palette]: typeof _palette[k] extends Color[] ? string[] : string;
};

export const palette: Palette = Object.keys(_palette)
  .reduce<Palette>((accum, colorName) => {
    if (_palette[colorName] instanceof Array) {
      accum[colorName] = _palette[colorName].map((color) => color);
    } else {
      accum[colorName] = _palette[colorName];
    }
    return accum;
  // @TODO:
  // @ts-ignore
  }, {});


// export const breakpoints = {
//   xs: 0,
//   sm: 320,
//   md: 768,
//   lg: 992,
//   xl: 1200,
// };

export const theme = {
  // breakpointsValues: breakpoints,

  // breakpoints: {
  //   xs: `${breakpoints.xs}px`,
  //   sm: `${breakpoints.sm}px`,
  //   md: `${breakpoints.md}px`,
  //   lg: `${breakpoints.lg}px`,
  //   xl: `${breakpoints.xl}px`,
  // },

  colors: {
    palette,
  },
};

export default theme;
