// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';
import * as R                                    from 'ramda';
// import uniqid                                    from 'uniqid';
// import * as qs                                   from 'query-string';
import * as color                                from 'color';
import styled, { css }                           from 'styled-components';
// import * as ta                                   from 'typesafe-actions';
// import * as reactRedux                           from 'react-redux';
// import * as reselect                             from 'reselect';
// import * as Yup                                  from 'yup';

// Common
// import * as hooks                                from 'hooks/index';
import * as helpers                              from 'helpers/index';
//import * as types                                from 'lib/types/index';

// Components
// import * as intl                                 from 'react-intl';
// import * as Formik                               from 'formik';
// import * as Helmet                               from 'react-helmet';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
// import * as Svg                                  from 'components/@UI/@Svg';


const colors = helpers.theme.colors;


export type ButtonTypes    = 'submit'  | 'reset'     | 'button';
export type ButtonVariants = 'text'    | 'fill'      | 'outline';
export type ButtonColors   = 'primary' | 'secondary' | 'default' | 'bluepanLight' | 'danger' | 'pink';
export type ButtonSizes    = 'xs'      | 'sm'        | 'md'      | 'lg'           | 'xl';
export type ButtonShapes   = 'circle'  | 'round';

const buttonColors = {
  disabled: {
    color:      colors.palette.lightBlack,
    background: colors.palette.background,
  },
  text: {
    primary: {
      color:      colors.palette.accent,
      background: colors.palette.accent,
    },
    secondary: {
      color:      colors.palette.black,
      background: colors.palette.icon,
    },
    default: {
      color:      colors.palette.icon,
      background: colors.palette.gray,
    },
    bluepanLight: {
      color:      colors.palette.bluepanLight,
      background: colors.palette.bluepanLight,
    },
    danger: {
      color:      colors.palette.red,
      background: colors.palette.red,
    },
    pink: {
      color:      colors.palette.pink,
      background: colors.palette.pink,
    },
  },

  fill: {
    primary: {
      color:      colors.palette.white,
      background: colors.palette.accent,
    },
    secondary: {
      color:      colors.palette.white,
      background: colors.palette.icon,
    },
    default: {
      color:      colors.palette.white,
      background: colors.palette.gray,
    },
    bluepanLight: {
      color:      colors.palette.white,
      background: colors.palette.bluepanLight,
    },
    danger: {
      color:      colors.palette.white,
      background: colors.palette.red,
    },
    pink: {
      color:      colors.palette.white,
      background: colors.palette.pink,
    },
  },
  outline: {
    primary: {
      color:      colors.palette.accent,
      background: colors.palette.accent,
    },
    secondary: {
      color:      colors.palette.black,
      background: colors.palette.icon,
    },
    default: {
      color:      colors.palette.icon,
      background: colors.palette.gray,
    },
    bluepanLight: {
      color:      colors.palette.bluepanLight,
      background: colors.palette.bluepanLight,
    },
    danger: {
      color:      colors.palette.red,
      background: colors.palette.red,
    },
    pink: {
      color:      colors.palette.pink,
      background: colors.palette.pink,
    },
  },
};

const sizeValues = (square: boolean) => ({
  xs: {
    ...(square ? { width: '29px' } : { px: '12px' }),
    height: '29px',
    fontSize: '13px',
  },
  sm: {
    ...(square ? { width: '32px' } : { px: '10px' }),
    height: '32px',
    fontSize: '14px',
    fontWeight: 500,
  },
  md: {
    ...(square ? { width: '36px' } : { px: '26px' }),
    height: '36px',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  },
  lg: {
    ...(square ? { width: '40px' } : { px: '16px' }),
    height: '40px',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  },
  xl: {
    ...(square ? { width: '52px' } : { px: '32px' }),
    height: '52px',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  },
});

const shapeValues = {
  rectangle: {},
  round: {
    borderRadius: '4px',
  },
  circle: {
    borderRadius: '50%',
  },
};

export interface ButtonStyledProps extends helpers.WithStyles<L.InlineFlexProps>, UI.FormGroupChildrenProps {
  type?:        ButtonTypes;
  variant?:     ButtonVariants;
  buttonColor?: ButtonColors;
  size?:        ButtonSizes;
  shape?:       ButtonShapes;
  disabled?:    boolean;
  square?:      boolean;
  // @TODO:
  fixHover?:  'fill' | 'stroke' | 'both';
}

export const StyledButton = styled(L.InlineFlex)
.attrs<ButtonStyledProps>(({ styles }) => ({
  ...styles,
}))<ButtonStyledProps>`
  justify-content: center;
  align-items: center;
  outline: none;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
  user-select: none;
  text-decoration: none;

  circle,
  rect,
  path {
    transition: stroke 0.25s ease-in-out, fill 0.25s ease-in-out;
  }

  ${({ disabled, variant, buttonColor, theme, formGroup, fixHover }) => {
    const styles = disabled
      ? buttonColors.disabled
      : buttonColors[variant][buttonColor];

    const backgroundColor = R.path<string>(['colors', ...styles.background.split('.')], theme);
    const fontColor       = R.path<string>(['colors', ...styles.color.split('.')],      theme);

    return css`
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${fontColor};
      text-decoration: none;

      &:visited {
        color: ${fontColor} !important;
      }

      ${formGroup && (formGroup.position === 'first' || formGroup.position === 'middle') && css`
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      `}

      ${formGroup && (formGroup.position === 'last' || formGroup.position === 'middle') && css`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      `}

      ${variant === 'text' && css`
        padding: 0;
        height: auto;

        ${disabled && css`
          color: ${theme.colors.palette.gray};

          circle,
          rect,
          path {
            ${(fixHover !== 'fill'   && fixHover !== 'both') && `fill: ${theme.colors.palette.gray};`}
            ${(fixHover !== 'stroke' && fixHover !== 'both') && `stroke: ${theme.colors.palette.gray};`}
          }
        `}
      `}

      ${variant === 'fill' && css`
        background: ${backgroundColor};

        ${!disabled && css`
          &:hover {
            background: ${color(backgroundColor).lighten(0.08).hex()};
          }

          &:active {
            background: ${color(backgroundColor).darken(0.12).hex()};
          }
        `}
      `}

      ${variant === 'outline' && css`
        box-shadow: 0px 0px 0px 1px inset ${backgroundColor};

        ${disabled && css`
          color: ${theme.colors.palette.gray};
        `}

        ${!disabled && css`
          &:hover {
            color: #fff;
            background: ${color(backgroundColor).hex()};

            circle,
            rect,98
            
            path {
              ${(fixHover !== 'fill'   && fixHover !== 'both') && 'fill: #fff;'}
              ${(fixHover !== 'stroke' && fixHover !== 'both') && 'stroke: #fff;'}
            }
          }

          &:active {
            background: ${color(backgroundColor).darken(0.12).hex()};
          }
        `}
      `}
    `;
  }}
`;

StyledButton.defaultProps = {
  as: 'button',
  border: 'none',
  bg: 'transparent',
};

export type ButtonClassKey = 'root' | 'disabled' | 'focusVisible';

  // @ts-ignore
export type ButtonProps<
  D extends React.ElementType =  ButtonTypeMap['defaultComponent'],
  P = {}
> = {
  children?:  React.ReactNode;
  type?:      ButtonTypes;
  variant?:   ButtonVariants;
  color?:     ButtonColors;
  size?:      ButtonSizes;
  shape?:     ButtonShapes;
  disabled?:  boolean;
  square?:    boolean;
  styles?:    L.InlineFlexProps;

  // @TODO:
  fixHover?:  'fill' | 'stroke' | 'both';

  onClick?:  (event: React.MouseEvent) => void;
};

export interface ButtonTypeMap<P = {}, D extends React.ElementType = 'button'> {
  props: ButtonProps<D> & P;
  defaultComponent: D;
  classKey: ButtonClassKey;
}

export interface ExtendButtonTypeMap<M extends UI.OverridableTypeMap> {
  props: ButtonTypeMap['props'] & M['props'];
  defaultComponent: M['defaultComponent'];
  classKey: M['classKey'];
}

export type ExtendButton<M extends UI.OverridableTypeMap> = (
  (props: UI.OverrideProps<ExtendButtonTypeMap<M>, 'a'>, ref: React.Ref<any>) => JSX.Element
) & UI.OverridableComponent<ExtendButtonTypeMap<M>>;

export const Button: ExtendButton<ButtonTypeMap> = React.forwardRef((
  {
    children,
    component,
    type,
    disabled = false,
    color    = 'default',
    variant  = 'fill',
    size     = 'md',
    shape    = 'round',
    square,
    formGroup,
    styles,
    fixHover,
    onClick  = () => {},
    ...props
  }: ButtonProps<any> & { component?: any, formGroup?: any },
  ref: React.Ref<any>,
) => {
  const as = component ? { as: component } : {};

  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      buttonColor={color}
      {...sizeValues(square)[size]}
      formGroup={formGroup}
      fixHover={fixHover}
      ref={ref}
      {...props}
      {...as}
      styles={{
        borderRadius: shapeValues[shape],
        alignItems: 'center',
        color: 'palette.black',
        ...styles,
      }}
    >
      {children}
    </StyledButton>
  );
});
