// Dependencies
import styled, { CSSProperties } from 'styled-components';
import {
  color,
  border,
  background,
  typography,
  position,
  BorderProps,
  BackgroundProps,
  TypographyProps,
  PositionProps,
  compose,
  system,
}                                from 'styled-system';

// Components
import { Space, SpaceProps }     from './Space';

// Helpers
import * as helpers              from 'helpers/index';


interface CursorProps {
  cursor?: CSSProperties['cursor'];
}

interface WhiteSpaceProps {
  whiteSpace?: CSSProperties['whiteSpace'];
}

interface TextTransformProps {
  textTransform?: CSSProperties['textTransform'];
}

interface UserSelectProps {
  userSelect?: CSSProperties['userSelect'];
}

interface TransitionProps {
  transition?: CSSProperties['transition'];
}

interface TransformProps {
  transform?: CSSProperties['transform'];
}

interface BoxShadowProps {
  boxShadow?: CSSProperties['boxShadow'];
}

interface TextDecorationProps {
  textDecoration?: CSSProperties['textDecoration'];
}

interface AnimationProps {
  animation?: CSSProperties['animation'];
}

const cursor = system({
  cursor: true,
});

const wordBreak = system({
  whiteSpace: true,
});

const textTransform = system({
  textTransform: true,
});

const userSelect = system({
  userSelect: true,
});

const transition = system({
  transition: true,
});

const transform = system({
  transform: true,
});

const boxShadow = system({
  boxShadow: true,
});

const textDecoration = system({
  textDecoration: true,
});

const animation = system({
  animation: true,
});

export interface BoxProps extends
  SpaceProps,
  helpers.FixedColorProps,
  BorderProps,
  BackgroundProps,
  TypographyProps,
  PositionProps,
  CursorProps,
  // OverflowProps,
  WhiteSpaceProps,
  TextTransformProps,
  UserSelectProps,
  TransitionProps,
  TransformProps,
  BoxShadowProps,
  TextDecorationProps,
  AnimationProps
{}

const enhancedBox = compose(
  color,
  border,
  background,
  typography,
  position,
  cursor,
  // overflow,
  wordBreak,
  textTransform,
  userSelect,
  transition,
  transform,
  boxShadow,
  textDecoration,
  animation,
);

export const Box = styled(Space)<BoxProps>`
  ${enhancedBox}
`;
