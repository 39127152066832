

const palette = {
  white:        '#FFFFFF',
  darkWhite:    '#F8F9FB',
  background:   '#EFF1F5',
  stroke:       '#E5E7EF',
  stroke2:      '#D0D4E1',
  gray:         '#BFC4D4',
  icon:         '#828BB8',
  lightBlack:   '#6B7297',
  black:        '#333333',
  bluepan:      '#332780',
  bluepanLight: '#4442A8',
  accent:       '#5552ED',
  dimLight:     '#ABAEEB',
  active:       '#F2F6FF',
  green:        '#5CC873',
  lime:         '#98C85C',
  yellow:       '#ECCB1C',
  orange:       '#EC991C',
  red:          '#DB7171',
  pink:         '#E36A9D',
  darkPink:     '#B43F85',
  darkGray:     '#888B9D',
};

export default palette;
