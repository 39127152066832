// Dependencies
import styled      from 'styled-components';
import {
  space,
  layout,
  SpaceProps as sSpaceProps,
  LayoutProps,
  compose,
}                 from 'styled-system';

// Helpers
import { WithAs } from 'helpers/styled';


export interface SpaceProps extends
sSpaceProps,
LayoutProps {}

const enhancedSpace = compose(
  space,
  layout,
);

export const Space = styled.div<WithAs<SpaceProps>>`
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  ${enhancedSpace}
`;
