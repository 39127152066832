// Dependencies
import styled            from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  compose,
}                        from 'styled-system';

// Components
import { Box, BoxProps } from './Box';


export interface InlineFlexProps extends BoxProps, FlexboxProps {}

const enhancedInlineFlex = compose(
  flexbox,
);

export const InlineFlex = styled(Box)<InlineFlexProps>`
  display: inline-flex;

  ${enhancedInlineFlex}
`;
