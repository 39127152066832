// Dependencies
import styled            from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  compose,
}                        from 'styled-system';

// Components
import { Box, BoxProps } from './Box';


const enhancedFlex = compose(
  flexbox,
);

export interface FlexProps extends FlexboxProps, BoxProps {}

export const Flex = styled(Box)<FlexProps>`
  ${enhancedFlex}
`;

Flex.defaultProps = {
  display: 'flex',
};
