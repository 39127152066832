// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';
import * as R                                    from 'ramda';
// import uniqid                                    from 'uniqid';
// import * as qs                                   from 'query-string';
// import * as color                                from 'color';
import styled, { ThemeContext }             from 'styled-components';
// import * as ta                                   from 'typesafe-actions';
// import * as reactRedux                           from 'react-redux';
// import * as reselect                             from 'reselect';
// import * as Yup                                  from 'yup';

// Common
// import * as $styled                              from '@eunikitin/styled';
// import * as hooks                                from 'hooks/index';
import * as helpers                              from 'helpers/index';
// import * as types                                from 'lib/types/index';

// Components
// import * as intl                                 from 'react-intl';
// import * as Formik                               from 'formik';
// import * as Helmet                               from 'react-helmet';

// import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';


// const colors = helpers.theme.colors;


const StyledSvg = styled(L.Box)<SvgProps>``;

export interface SvgProps  {}

export interface SvgComponentProps extends helpers.WithStyles<L.BoxProps> {
  width?:    string;
  height?:   string;
  color?:    string;
  viewBox?:  string;
  fill?:     string;
  xmlns?:    string;
  ref?:      React.Ref<any>,
  children:  (props: SvgComponentProps) => React.ReactNode;

  onClick?:  (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

export const Svg: React.FC<SvgComponentProps> = React.forwardRef((
  props,
  ref,
) => {
  const   {
    // @ts-ignore
    styles,
    color  = '#000000',
    width  = '16px',
    height = '16px',
    xmlns  = 'http://www.w3.org/2000/svg',
    fill   = 'none',
    children,
    ...otherProps
  } = props;
  const theme  = React.useContext(ThemeContext);
  const _color: object = color ? { color: color[0] === '#' ? color : R.path(['colors', ...color.split('.')], theme) } : {};

  return (
    // @ts-ignore
    <StyledSvg
      as={'svg'}
      width={width || '100%'}
      height={height || '100%'}
      color={_color}
      xmlns={xmlns}
      fill={fill}
      ref={ref}
      {...styles}
      {...otherProps}
    >
      {children({ ...props, ..._color })}
    </StyledSvg>
  );
});
