// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';
import styled from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as helpers                              from 'helpers/index';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as Svg                                  from 'components/@UI/@Svg';


const colors = helpers.theme.colors;

const Container = styled(L.Flex)`
  background: #EFF1F5;
`;

const ImageContainer = styled(L.Flex)``;

const Content = styled(L.Flex)`
  margin-top: 47px;
  justify-content: space-between;
`;

const NavLink = styled('a')`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #535875;
  text-decoration: none;

  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    height: 2px;
    background: #E3E6ED;
    width: 100%;
  }

  width: fit-content;
`;

const Column = styled(L.Flex)`
  flex-direction: column;
`;

const NavItem = styled('a')`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: #888B9D;
  text-decoration: none;
  margin-top: 20px;
  width: fit-content;
`;

interface Data {
  main: {
    src: string;
    text: string;
  };
  items: {
    src: string;
    text: string;
  }[];
}

const DATA: Data[] = [
  {
    main: {
      src: '/company',
      text: 'topFooter.company',
    },
    items: [
      {src: '/company/about-us', text: 'topFooter.company.aboutUs'},
      {src: '/company/customers', text: 'topFooter.company.customers'},
      {src: '/company/tariffs', text: 'topFooter.company.tariffs'},
      {src: '/company/command', text: 'topFooter.company.command'},
      {src: '/company/contacts', text: 'topFooter.company.contacts'},
    ]
  },
  {
    main: {
      src: '/products',
      text: 'topFooter.products',
    },
    items: [
      {src: '/products/responses-to-reviews', text: 'topFooter.products.responsesToReview'},
      {src: '/products/tools-for-aso', text: 'topFooter.products.toolsForAso'},
      {src: '/products/downloads-revenue', text: 'topFooter.products.downloadsRevenue'},
      {src: '/products/aso-consulting', text: 'topFooter.products.asoConsulting'},
      {src: '/products/top-charts', text: 'topFooter.products.topCharts'},
    ],
  },
  {
    main: {
      src: '/solutions',
      text: 'topFooter.solutions',
    },
    items: [
      {src: '/solutions/support-service', text: 'topFooter.solutions.supportService'},
      {src: '/solutions/game-companies', text: 'topFooter.solutions.gameCompanies'},
      {src: '/solutions/marketing', text: 'topFooter.solutions.marketing'},
      {src: '/solutions/product-development', text: 'topFooter.solutions.productDevelopment'},
    ],
  },
  {
    main: {
      src: '/resources',
      text: 'topFooter.resources',
    },
    items: [
      {src: '/resources/blog', text: 'topFooter.resources.blog'},
      {src: '/resources/help', text: 'topFooter.resources.help'},
      {src: '/resources/glossary', text: 'topFooter.resources.glossary'},
      {src: '/resources/api', text: 'topFooter.resources.api'},
      {src: '/resources/demo-access', text: 'topFooter.resources.demoAccess'},
    ],
  },
  {
    main: {
      src: '/our-difference',
      text: 'topFooter.ourDifference',
    },
    items: [
      {src: '/our-difference/vs-sensor-tower', text: 'topFooter.ourDifference.vsSensorTower'},
      {src: '/our-difference/vs-mobile-action', text: 'topFooter.ourDifference.vsMobileActionr'},
      {src: '/our-difference/vs-apptopia', text: 'topFooter.ourDifference.vsApptopia'},
      {src: '/our-difference/vs-apptweak', text: 'topFooter.ourDifference.vsApptweak'},
      {src: '/our-difference/vs-appbot', text: 'topFooter.ourDifference.vsAppbot'},
    ],
  },
  {
    main: {
      src: '/partners',
      text: 'topFooter.partners',
    },
    items: [
      {src: '/partners/branch', text: 'topFooter.partners.branch'},
      {src: '/partners/appmagic', text: 'topFooter.partners.appmagic'},
      {src: '/partners/splitmetrics', text: 'topFooter.partners.splitmetrics'},
      {src: '/partners/become-a-partner', text: 'topFooter.partners.becomeAPartner'},
    ],
  },
];

export type TopFooterProps = {};

export const TopFooter: React.FC<TopFooterProps> = ({}) => (
  <Container>
    <L.Container flexDirection="column" pt="57px" pb="37px">
      <ImageContainer>
        <Svg.AsooneLogo
          width={'180px'}
          height={'40px'}
          color={colors.palette.darkGray}
        />
      </ImageContainer>
      <Content>
        {DATA.map(data => (
          <Column key={data.main.src}>
            <NavLink href={data.main.src}>
              <intl.FormattedMessage id={data.main.text} />
            </NavLink>
            {data.items.map(item => (
              <NavItem key={item.src} href={item.src}>
                <intl.FormattedMessage id={item.text} />
              </NavItem>
            ))}
          </Column>
        ))}
      </Content>
    </L.Container>
  </Container>
);
