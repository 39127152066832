// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';

import * as UI                                   from '../Svg';


export interface StarProps {
  width?:  number;
  height?: number;
  color:   string;
}

export const Star: React.FC<StarProps> = React.forwardRef((
  props,
  ref: React.Ref<any>,
) => {
  return (
    <UI.Svg
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
    {({ color }) => (
      <path d="M10.5 0L13.1968 6.78811L20.4861 7.25532L14.8636 11.9178L16.6717 18.9947L10.5 15.0881L4.32825 18.9947L6.13642 11.9178L0.513906 7.25532L7.80316 6.78811L10.5 0Z" fill={color}/>
    )}
    </UI.Svg>
  );
});
