// Dependencies

// Dependencies
// import * as React                                from 'react';
import _                                         from 'lodash';
// import * as R                                    from 'ramda';
// import uniqid                                    from 'uniqid';
// import * as qs                                   from 'query-string';
// import * as color                                from 'color';
import styled, { css, CSSProperties, keyframes } from 'styled-components';
import { compose, system }                       from 'styled-system';

import * as helpers                              from 'helpers/index';

// Components
// import * as Helmet                               from 'react-helmet';

// import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
// import * as Svg                                  from 'components/@UI/@Svg';


const colors = helpers.theme.colors;


const textOverflow = system({
  textOverflow: true,
});

interface TextOverflowProps {
  textOverflow?: CSSProperties['textOverflow'];
}

const enhancedText = compose(
  textOverflow,
);

export interface TextProps extends L.BoxProps, TextOverflowProps {
  skeleton?:      boolean;
  skeletonColor?: string;
}

export const Text = styled(L.Flex)<helpers.WithAs<TextProps>>`
  ${enhancedText}

  ${({ skeleton, skeletonColor = 'rgba(206,217,224, 0.2)' }) => skeleton && helpers.skeletonStyles(skeletonColor)}
`;

Text.defaultProps = {
  as: 'span',
  display: 'inline',
  fontFamily: 'Raleaway, sans-serif',
  lineHeight: 1,
};

export const Bold = styled(L.Box)<helpers.WithAs<TextProps>>``;

Bold.defaultProps = {
  as: 'b',
  display: 'inline',
};

export const Bold14 = styled(Text)<helpers.WithAs<TextProps>>``;

Bold14.defaultProps = {
  fontSize: '14px',
  fontWeight: 700,
};

export const Bold15 = styled(Text)<helpers.WithAs<TextProps>>``;

Bold15.defaultProps = {
  fontSize: '15px',
  fontWeight: 700,
};

export const Bold16 = styled(Text)<helpers.WithAs<TextProps>>``;

Bold16.defaultProps = {
  fontSize: '16px',
  fontWeight: 700,
};

export const Bold32 = styled(Text)<helpers.WithAs<TextProps>>``;

Bold32.defaultProps = {
  fontSize: '32px',
  fontWeight: 700,
};

export const Bold48 = styled(Text)<helpers.WithAs<TextProps>>``;

Bold48.defaultProps = {
  fontSize: '48px',
  fontWeight: 700,
};

export const Medium16 = styled(Text)<helpers.WithAs<TextProps>>``;

Medium16.defaultProps = {
  fontSize: '16px',
  fontWeight: 500,
};

export const Medium18 = styled(Text)<helpers.WithAs<TextProps>>``;

Medium18.defaultProps = {
  fontSize: '18px',
  fontWeight: 500,
};

export const Medium32 = styled(Text)<helpers.WithAs<TextProps>>``;

Medium32.defaultProps = {
  fontSize: '32px',
  fontWeight: 500,
};

export const Semibold12 = styled(Text)<helpers.WithAs<TextProps>>``;

Semibold12.defaultProps = {
  fontSize: '12px',
  fontWeight: 600,
};

export const Semibold14 = styled(Text)<helpers.WithAs<TextProps>>``;

Semibold14.defaultProps = {
  fontSize: '14px',
  fontWeight: 600,
};

export const Semibold17 = styled(Text)<helpers.WithAs<TextProps>>``;

Semibold17.defaultProps = {
  fontSize: '17px',
  fontWeight: 600,
};

export const Semibold18 = styled(Text)<helpers.WithAs<TextProps>>``;

Semibold18.defaultProps = {
  fontSize: '18px',
  fontWeight: 600,
};

export const Regular12 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular12.defaultProps = {
  fontSize: '12px',
};


export const Regular13 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular13.defaultProps = {
  fontSize: '13px',
};

export const Regular14 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular14.defaultProps = {
  fontSize: '14px',
  lineHeight: '20px',
};

export const Regular15 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular15.defaultProps = {
  fontSize: '15px',
  lineHeight: '25px',
};

export const Regular16 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular16.defaultProps = {
  fontSize: '16px',
  lineHeight: '24px',
};

export const Regular17 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular17.defaultProps = {
  fontSize: '17px',
  lineHeight: '24px',
};

export const Regular18 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular18.defaultProps = {
  fontSize: '18px',
  lineHeight: '25px',
};

export const Regular24 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular24.defaultProps = {
  fontSize: '24px',
  lineHeight: '33px',
};

export const Regular32 = styled(Text)<helpers.WithAs<TextProps>>``;

Regular32.defaultProps = {
  fontSize: '32px',
  lineHeight: '37px',
};

export const Light32 = styled(Text)<helpers.WithAs<TextProps>>``;

Light32.defaultProps = {
  fontSize: '32px',
  fontWeight: 300,
};
