// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';
import styled                                    from 'styled-components';

// Common
import * as helpers                              from 'helpers/index';

// Components
import * as L                                    from 'components/@UI/@Layout';


// @TODO: What generic type?
export interface ImageProps extends React.ImgHTMLAttributes<any> {}

export const Image = styled(L.Box)<helpers.WithAs<ImageProps>>`
  display: block;
`;

Image.defaultProps = {
  as: 'img',
};
