// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';

import * as UI                                   from '../Svg';


export interface TimelineProps {
  width?:  number;
  height?: number;
  color:   string;
}

export const Timeline: React.FC<TimelineProps> = React.forwardRef((
  props,
  ref: React.Ref<any>,
) => {
  return (
    <UI.Svg
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
      width="19px"
      height="15px"
    >
    {({ color }) => (
      <>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="15">
        <rect width="19" height="15" fill={color} />
        </mask>
        <g mask="url(#mask0)">
        <path d="M16.5 -1L13 8H7L5 13H0" stroke={color} strokeWidth="4"/>
        </g>
      </>
    )}
    </UI.Svg>
  );
});
