// Dependencies
import * as R                      from 'ramda';
import iStyled, { css, keyframes } from 'styled-components';
import { ColorProps }              from 'styled-system';
import * as color                  from 'color';

// Shared
import { theme as themeObject }    from '../configs/styled/theme';


export default iStyled;
export const create = iStyled;

export * from 'styled-components';

export const size = (value: number, points: 'px' | 'em' | 'rem') => `${value}${points}`;
export const px   = (value: number) => size(value, 'px');
export const em   = (value: number) => size(value, 'em');

export interface FixedColorProps extends ColorProps {
  color?: string;
}

export type WithAs<T = {}> = T & { as?: keyof JSX.IntrinsicElements | React.ComponentType<any>; };

export interface WithStyles<T> {
  styles?: T;
}

const buildTheme = (tree: object, omit: string[]): typeof themeObject => {
  const _buildTheme = (tree: object, str: string, result: object, omit: string[] = []) => {
    Object.keys(tree).forEach((branch) => {
      const nestedBranch = str === '' ? branch : `${str}.${branch}`;
      if (typeof tree[branch] === 'object') {
        // tslint:disable-next-line: no-parameter-reassignment
        result = _buildTheme(tree[branch], nestedBranch, result, omit);
      } else {
        // tslint:disable-next-line: no-parameter-reassignment
        result = R.assocPath(
          nestedBranch.split('.'),
          nestedBranch.split('.')
            .filter((item) =>
              omit.some((omitItem) => omitItem !== item,
            ))
            .join('.'),
          result,
        );
      }
    });

    return result;
  };

  return _buildTheme(tree, '', {}, omit) as typeof theme;
};


export const theme = buildTheme(themeObject, ['colors']);

export const skeletonGlow = (skeletonColor: string) => keyframes`
  from {
    background: ${skeletonColor};
  }

  to {
    background: ${color(skeletonColor).darken(0.3).rgb().toString()};
  }
`;

export const skeletonStyles = (skeletonColor: string) => css`
  cursor: default;
  border-color: ${skeletonColor}!important;
  background: ${skeletonColor};
  border-radius: 3px;
  color: transparent!important;
  animation: ${skeletonGlow(skeletonColor)} 1s linear infinite alternate;
  user-select: none;
  box-shadow: none !important;
  pointer-events: none;
`;
