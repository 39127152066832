// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';
import * as R                                    from 'ramda';
// import uniqid                                    from 'uniqid';
// import * as qs                                   from 'query-string';
import * as color                                from 'color';
import styled, { css }                           from 'styled-components';
// import * as ta                                   from 'typesafe-actions';
// import * as reactRedux                           from 'react-redux';
// import * as reselect                             from 'reselect';
// import * as Yup                                  from 'yup';

// Common
// import * as hooks                                from 'hooks/index';
import * as helpers                              from 'helpers/index';
//import * as types                                from 'lib/types/index';

// Components
// import * as intl                                 from 'react-intl';
// import * as Formik                               from 'formik';
// import * as Helmet                               from 'react-helmet';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
// import * as Svg                                  from 'components/@UI/@Svg';

export const Link = styled('a')``;
