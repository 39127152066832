// Dependencies
import * as React                                from 'react';
import * as gatsby                               from 'gatsby';

import styled                                    from 'styled-components';
import * as intl from 'gatsby-plugin-intl';

import * as UI                                   from 'components/@UI';
import * as L                                    from 'components/@UI/@Layout';
import * as Svg                                  from 'components/@UI/@Svg';

const Container = styled(L.Flex)`
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;
  margin: 0 68px;
`;

const NavLink = styled('a')`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #3D4156;
  text-decoration: none;

  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    height: 2px;
    background: #E3E6ED;
    width: 100%;
  }
`;

interface LinkData {
  text: string;
  src: string;
}

const LINKS: LinkData[] = [
  { text: 'header.nav.products', src: '/products' },
  { text: 'header.nav.integrations', src: '/integrations' },
  { text: 'header.nav.resources', src: '/resources' },
  { text: 'header.nav.tariffs', src: '/tariffs' },
  { text: 'header.nav.clients', src: '/clients' },
];


export type NavigationProps = {};

export const Navigation: React.FC<NavigationProps> = ({}) => (
  <Container>
    {LINKS.map(link => (
      <NavLink key={link.src} href={link.src}>
        <intl.FormattedMessage id={link.text} />
      </NavLink>
    ))}
  </Container>
);
