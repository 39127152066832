// Dependencies
import * as React                                from 'react';
import _                                         from 'lodash';

import * as UI                                   from '../Svg';


export interface ArrowProps {
  width?:  number;
  height?: number;
  color:   string;
}

export const Arrow: React.FC<ArrowProps> = React.forwardRef((
  props,
  ref: React.Ref<any>,
) => {
  return (
    <UI.Svg
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
    {({ color }) => (
      <>
        <path d="M0 8H20M20 8L13 1M20 8L13 15" stroke={color} strokeWidth="2"/>
      </>
    )}
    </UI.Svg>
  );
});
